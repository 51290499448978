import Button from 'react-bootstrap/Button';
import Icon from './icon';

function Btn(props) {
    return (
        <Button variant="primary" size="lg" onClick={props.onClick} disabled={props.disabled} className={`border-0 d-flex align-items-center justify-content-center ${props.className}`}>
            {props.icon &&
                <Icon icon={props.icon} color="white" size={30} className="me-2" />
            }
            <span>{props.label}</span>
        </Button>
    );
}

export default Btn;
