
function TopDepartment(props) {
    return (
        <div className="d-flex flex-row justify-content-around pt-3 pb-3">

            <div className="w-100 text-center">
                <div className={`${props.className} d-flex h-100 justify-content-center align-items-center position-relative w-100`}>
                    {props.image && <img src={props.image} alt="icon"></img>}
                    <div className="position-absolute">{props.rank}</div>
                </div>
            </div>
            <div className="w-100 text-start text-white">
                {props.name}
            </div>
            <div className="w-100 text-brand text-center">
                {props.score}%
            </div>
        </div>
    );
}

export default TopDepartment;