
function Content(props) {
    return (
        <div className={`ps-2 pe-2 pt-2 ${props.className}`} >
            {props.children}
        </div>
    );
}

export default Content;
