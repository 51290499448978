import { useParams } from "react-router-dom";
import Content from "../components/content";
import AgentProfile from "../components/agentprofile";
import ButtonBack from "../components/buttonback";

function Me() {

    const { agentCode } = useParams();

    return (
        <>
            <AgentProfile agentCode={agentCode} />
            <Content className="mb-5">
                <div>
                    <ButtonBack to="/leader-board" />
                </div>
            </Content>

        </>
    );
}

export default Me;
