import Reward from "./reward";
import star from "../img/trophies/star.png";
import medal from "../img/trophies/medal.png";
import spoon from "../img/trophies/spoon.png";

function Rewards(props) {
    return (
        <div className="">
            <div className="mb-4 mt-3 d-flex flex-row justify-content-between">
                <div className="text-uppercase text-white font-size-18px">Target</div>
                <div className="text-uppercase text-brand font-size-18px">30 Sales</div>
            </div>
            <Reward image={star} label="Gold" score="5" />
            <Reward image={medal} label="Silver" score="2" />
            <Reward image={spoon} label="Bronze" score="6" />
        </div>
    );
}

export default Rewards;
