import { Link } from "react-router-dom";
import AgentImage from "./agentimage";

function TopAgent(props) {
    return (
        <div className={props.className}>
            <div className="position-relative ms-2 me-2 ">
                <div className="d-flex align-items-center ps-2 pe-2 position-absolute bg-brand rounded-top-right-3" style={{ right: "0px", aspectRatio: "1/1" }}>
                    <span className="font-size-18px">{props.rank}</span>
                </div>
                <Link to={`/agent/${props.agent.agentCode}`}>
                    <AgentImage className="border-brand rounded-3 mb-2 w-100" image={props.agent?.profileImage?.fileurl} />
                </Link>
            </div>
            <div className="letter-spacing-3px text-white">
                {props.agent.fullName}
            </div>
            <div className="font-size-20px letter-spacing-3px text-white">
                {props.score}
            </div>
        </div>
    );
}

export default TopAgent;