import { useState, useEffect } from 'react';
import useLocalStorage from "use-local-storage";
import { useFilePicker } from 'use-file-picker';
import { createFile, updateAgentProfileImageByAgentId } from '../lib/fetchData';
import { useNavigate } from "react-router-dom";

import Content from "../components/content";
import LinkButton from "../components/linkbutton";
import ButtonBack from '../components/buttonback';


function Me() {

    const navigate = useNavigate();
    const [user] = useLocalStorage("user", "");
    const [createFileResult, setCreateFileResult] = useState();

    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        readAs: 'ArrayBuffer',
        accept: 'image/*',
        multiple: false,
        limitFilesConfig: { max: 1 },
        // minFileSize: 0.1, // in megabytes
        maxFileSize: 50,

    });

    const setProfileImage = async () => {
        await updateAgentProfileImageByAgentId(user["_id"], createFileResult);
        navigate('/me');
    }

    useEffect(() => {
        const create = async (fileName, content) => {
            const result = await createFile(fileName, content);

            setCreateFileResult({
                "profileImage": {
                    "fileName": result.filename,
                    "originalFileName": fileName,
                    "fileurl": result.fileurl,
                }
            });
        }
        if (filesContent.length) {
            create(filesContent[0].name, filesContent[0].content);
        }
    }, [filesContent, setCreateFileResult]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (errors.length) {
        return <div>Error...</div>;
    }

    return (
        <>

            <Content className="">
                <div>
                    <div className="pt-5 pb-5">
                        <ButtonBack to="/me" />
                    </div>
                    <div>
                        <img src="" alt="" />
                    </div>

                    {createFileResult && createFileResult.profileImage &&
                        <div className="d-flex justify-content-center">
                            <div className=" p-2 w-50">
                                <img className="border-brand rounded w-100" src={createFileResult.profileImage.fileurl} alt="" />
                            </div>

                        </div>
                    }
                    <div className="d-flex flex-row">
                        <div className="w-100">
                            <LinkButton className="rounded-0" label="Choose Image" onClick={() => openFileSelector()} />
                        </div>
                        <div>
                            <LinkButton className="rounded-0" disabled={!createFileResult} label="Save" onClick={() => setProfileImage()} />
                        </div>
                    </div>
                </div>
            </Content>


        </>
    );
}

export default Me;
