
function SaleScore(props) {
    return (
        <div className="bg-input h-60px rounded-4 d-flex flex-row justify-content-between align-items-center font-size-25px mb-2">
            <div className="ps-3 text-white font-weight-500">
                {props.label}
            </div>
            <div className="pe-3 text-brand font-weight-600">
                {props.score}
            </div>
        </div>
    );
}

export default SaleScore;
