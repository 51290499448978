import { useFetchAgents } from '../hooks/useFetch';

import TopAgent from "./topagent";

function TopAgents(props) {

    const [agents] = useFetchAgents();

    function agentRank(i) {
        return i === 0 ? '2' : i === 1 ? '1' : '3';
    }

    return (
        <div className="text-center">
            <div className="font-size-24px font-weight-600 text-white mb-2">
                Top Agents
            </div>
            <div className="d-flex flex-row justify-content-between">
                {agents?.map && agents?.map((o, i) => {
                    return <TopAgent key={o.agentCode} className={i !== 1 ? 'mt-4 w-100' : 'w-100 '} rank={agentRank(i)} agent={o} score="20" />
                })}
            </div>
        </div>
    );
}

export default TopAgents;
