
import Backdrop from "../components/backdrop";
import Content from "../components/content";
import Nav from "../components/nav";
import TopAgents from "../components/topagents";
import { TopDepartments } from "../components/topdepartments";

function Home() {
    return (
        < >
            <Backdrop>
                <TopDepartments />
            </Backdrop>
            <Content>
                <TopAgents />
            </Content>
            <div className="fixed-bottom">
                <Nav />
            </div>

        </>
    );
}

export default Home;
