

import Nav from "../components/nav";
import SocialPosts from "../components/socialposts";
import Content from "../components/content";
import weather from "../img/weather/lightrain.svg";
import SocialQuote from "../components/socialquote";
import { NowDate, NowTime } from "../components/now";

function Social() {
    return (
        <>

            <div className="d-flex position-relative align-items-center">
                <div className="position-absolute p-3 text-white">
                    <div className="d-flex flex-row font-size-20px font-weight-500 letter-spacing-3px  justify-content-evenly">
                        <div className="w-100"><NowDate /></div>
                        <div className="w-100 text-end"><NowTime /></div>
                    </div>
                    <div className="pt-3 pb-3 align-items-center d-flex font-weight-300 font-size-50px justify-content-end">
                        <div><img className="w-80px h-80px" src={weather} alt="weather" /></div>
                        <div>18&deg;</div>
                    </div>
                    <div className="text-center text-shadow font-style-italic font-size-14px">
                        <SocialQuote />
                    </div>
                </div>

                <img className="w-100" src="https://files.elfsightcdn.com/_assets/weather/bg-images/01d.jpg" alt="weather" />

            </div>

            <Content className="rounded-3 bg-half ">
                <SocialPosts />
            </Content>

            <div className="fixed-bottom">
                <Nav />
            </div>

        </>
    );
}

export default Social;
