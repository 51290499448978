import TextBlock from "../components/textblock";
import Input from "../components/input";
import InputForm from '../components/inputform';
import LinkButton from "../components/linkbutton";
import ButtonBack from "../components/buttonback";
import Already from "../components/already";
import Content from "../components/content";

function SignUp() {
    return (
        <Content>
            <div className="pt-4 pb-4">
                <ButtonBack to="/" />
            </div>
            <div className="pb-4">
                <TextBlock top="Get Started" middle="Account Details" bottom="Please fill the details below to create your new account and get started." />
            </div>
            <div className="pb-3">
                <InputForm>
                    <Input id="name" label="Your name" autoComplete="name" placeholder="John Smith" type="text" />
                    <Input id="email" label="Your email" autoComplete="email" placeholder="example@email.com" type="email" />
                    <Input id="password" label="Password" autoComplete="new-password" placeholder="" type="password" />
                </InputForm>
            </div>
            <div className="pb-4">
                <LinkButton label="Next" to="/home" />
            </div>
            <Already />

        </Content >
    );
}

export default SignUp;
