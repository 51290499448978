const BASE_URL = "https://api.appery.io/rest/1/db";
const DATABASE_ID = "63a2c71a0f0d314f9335e20b";

export async function getSessionToken() {
    return JSON.parse(localStorage.getItem("sessionToken") || "");
}

export async function getHeaders(contentType) {
    const token = await getSessionToken();
    return {
        'Content-Type': contentType || 'application/json',
        'X-Appery-Database-Id': DATABASE_ID,
        'X-Appery-Session-Token': token,
    };
}

export async function updateAgentProfileImageByAgentId(agentId, body) {
    const url = encodeURI(`${BASE_URL}/collections/agents/${agentId}?full_object=false`);
    const headers = await getHeaders();
    const resp = await fetch(url, { method: 'PUT', headers, body: JSON.stringify(body) });
    const data = await resp.json();
    return data;
};

export async function createFile(fileName, content) {
    const url = encodeURI(`${BASE_URL}/files/${fileName}`);
    const headers = await getHeaders('image/png');
    const resp = await fetch(url, { method: 'POST', headers, body: content });
    const data = await resp.json();
    return data;
};