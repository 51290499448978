import noPicture from '../img/agents/no-picture.jpg';

function AgentImage(props) {

    if (props.waitForLoad && !props.isLoaded)
        return (<></>)

    return (
        <>
            {props.image &&
                <img className={props.className} src={props.image} alt="agent"></img>
            }
            {!props.image &&
                <img className={props.className} src={noPicture} alt=""></img>
            }
        </>
    );
}

export default AgentImage;
