import Link from "../components/link";

function DontHave(props) {
    return (
        <div className="text-center">
            Need an account? <Link to={`/sign-up`} label="sign up here" />
        </div>
    );
}

export default DontHave;
