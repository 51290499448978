import Backdrop from "../components/backdrop";
import Content from "../components/content";
import LinkButton from "../components/linkbutton";
import TextBlock from "../components/textblock";
import Already from "../components/already";
import Logo from "../components/logo";
import Strapline from "../components/strap";


function Started() {
    return (
        <div >
            <Backdrop>
                <div className="align-items-center d-flex h-100">
                    <div className="m-auto mb-5 mt-5 pb-5 pt-5 text-center">
                        <div className="">
                            <Logo />
                        </div>
                        <Strapline />
                    </div>
                </div>
            </Backdrop>

            <Content>
                <div className="mt-3 mb-4">
                    <TextBlock top="Welcome" middle="Let's Get Started" bottom="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's " />
                </div>
                <div className="mb-4">
                    <LinkButton to={'/sign-up'} label="Get Started" />
                </div>
                <Already />
            </Content>

        </div>
    );
}

export default Started;
