function Backdrop(props) {
    return (
        <div>
            <div className="bg-backdrop">
                <div className="bg-waves h-100">
                    {props.children}
                </div>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
                    <path className="fill-brand" d="M0,224L60,186.7C120,149,240,75,360,48C480,21,600,43,720,90.7C840,139,960,213,1080,213.3C1200,213,1320,139,1380,101.3L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
            </div>
        </div>
    );
}

export default Backdrop;
