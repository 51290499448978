import Link from "../components/link";

function Already(props) {
    return (
        <div className="text-center">
            Already have an account? <Link to={`/sign-in`} label="sign in here" />
        </div>
    );
}

export default Already;
