import useLocalStorage from "use-local-storage";

import Nav from "../components/nav";
import Content from "../components/content";
import Rewards from "../components/rewards";
import AgentProfile from "../components/agentprofile";

function Me() {

    const [user] = useLocalStorage("user", "");

    return (
        <>
            <AgentProfile allowEdit={true} agentCode={user?.agentCode} />
            <Content className="">
                <Rewards />
            </Content>
            <div className="fixed-bottom">
                <Nav />
            </div>

        </>
    );
}

export default Me;
