
import SaleScore from "../components/salescore";

function SaleScores(props) {
    return (
        <div>
            <SaleScore label="October" score="26" />
            <SaleScore label="September" score="21" />
            <SaleScore label="August" score="38" />
            <SaleScore label="July" score="29" />
        </div>
    );
}

export default SaleScores;
