function TextBlock(props) {
    return (
        <>
            <div className="text-uppercase text-brand letter-spacing-3px font-weight-500 font-size-12px">
                {props.top}
            </div>
            <div className="font-size-30px font-weight-600 text-white mb-2">
                {props.middle}
            </div>
            <div>
                {props.bottom}
            </div>
        </>
    );
}

export default TextBlock;
