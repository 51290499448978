
function Strapline(props) {
    return (
        <div className="letter-spacing-3px">
            THE ORACLE OF SALES
        </div>
    );
}

export default Strapline;
