import Form from 'react-bootstrap/Form';

function Input(props) {
    return (
        <>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control id={props.id} onInput={e => props.onInput ? props.onInput(e.target.value) : () => { }} className="bg-input mb-3" type={props.type} autoComplete={props.autoComplete} placeholder={props.placeholder} />
        </>
    );
}

export default Input;
