
function SocialPost(props) {
    return (
        <div className={`p-2 mb-2 bg-input d-flex flex-row rounded-3 ${props.className}`} >
            <div >
                <img src={props.image} alt="social" />
            </div>
            <div className="ps-2 pe-2">
                <div className="mb-2 text-white font-size-16px font-weight-500">{props.headline}</div>
                <div className="font-size-12px">{props.post}</div>
            </div>
        </div>
    );
}

export default SocialPost;
