import { useTime } from "use-time-react-hook";

export function NowDate() {

    const [time] = useTime();

    return (
        <span>{new Date(time).toLocaleDateString()}</span>
    );
}

export function NowTime() {

    const [time] = useTime();

    return (
        <span>{new Date(time).toLocaleTimeString().substring(0, 5)}</span>
    );
}
