function Reward(props) {
    return (
        <div className="d-flex justify-content-around align-items-center bg-input pt-3 pb-3 mb-3 rounded rounded-4">
            <div>
                <img src={props.image} alt="" />
            </div>
            <div className="font-size-22px font-weight-500">{props.label}</div>
            <div className="font-size-40px font-weight-800 text-brand">{props.score}</div>
        </div>
    );
}

export default Reward;
