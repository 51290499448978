import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,

} from "react-router-dom";

import App from './App';
import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import Started from './pages/started';
import reportWebVitals from './reportWebVitals';

import Home from './pages/home';
import Social from './pages/social';
import Me from './pages/me';
import MeEdit from './pages/me_edit';
import LeaderBoard from './pages/leaderboard';
import Profile from './pages/profile';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Started />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "social",
        element: <Social />,
      },
      {
        path: "me",
        element: <Me />,
      },
      {
        path: "me/edit",
        element: <MeEdit />,
      },
      {
        path: "leader-board",
        element: <LeaderBoard />,
      },
      {
        path: "agent/:agentCode",
        element: <Profile />,
      },
    ],
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
