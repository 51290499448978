import SocialPost from "./socialpost";
import social1 from "../img/social/social1.png"
import social2 from "../img/social/social2.png"
import social3 from "../img/social/social3.png"

function SocialPosts(props) {
    return (
        <div className="">
            <SocialPost image={social1} className="" headline="Company Headlines" post="Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has." />
            <SocialPost image={social2} className="flex-row-reverse" headline="Day in the life of a leader" post="Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has." />
            <SocialPost image={social3} className="" headline="John hits gold 10X" post="Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has." />
        </div>
    );
}

export default SocialPosts;
