import { Link } from "react-router-dom";

function Lnk(props) {
    return (
        <Link className="text-decoration-none text-brand" to={props.to}>
            {props.label}
        </Link>

    );
}

export default Lnk;
