import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthenticate } from '../hooks/useFetch';

import TextBlock from "../components/textblock";
import Input from "../components/input";
import LinkButton from "../components/linkbutton";
import ButtonBack from "../components/buttonback";
import Content from "../components/content";
import DontHave from "../components/donthave";
import InputForm from "../components/inputform";

function SignIn() {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authProps, setAuthProps] = useState();
    const [isLoggedIn, authError, isLoading] = useAuthenticate(authProps);

    const login = () => {
        if (username && password) {
            setAuthProps({ username, password });
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/home');
        }
    }, [isLoggedIn, navigate]);

    return (
        <Content>
            <div className="pt-5 pb-5">
                <ButtonBack to="/" />
            </div>

            <div className="pb-4">
                <TextBlock top="Welcome back!" middle="Sign in" bottom="If you're already registered, enter your credentials below to continue." />
            </div>

            <div className="pb-2">
                <InputForm>
                    <Input id="email" onInput={setUsername} label="Your email" autoComplete="email" placeholder="example@email.com" type="email" />
                    <Input id="password" onInput={setPassword} label="Password" autoComplete="current-password" placeholder="" type="password" />
                </InputForm>

                <div className="d-flex justify-content-center">
                    {authError?.code && authError?.description &&
                        <span>{authError.description}</span>
                    }
                </div>

            </div>


            <div className="pb-4">
                <LinkButton label="Sign in" onClick={login} icon="box-arrow-in-right" disabled={isLoading} />
            </div>

            <DontHave />

        </Content>
    );
}

export default SignIn;
