import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";


function NavItem(props) {

    const [navBg, setNavBg] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === props.to) {
            setNavBg("bg-brand");

        }
    }, [location, props.to]);

    return (
        <Link to={props.to}>
            <div className={`${navBg} h-50px w-50px d-flex align-items-center justify-content-center rounded-3`}>
                {props.children}
            </div>
        </Link>
    );
}

export default NavItem;
