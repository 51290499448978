import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import arrow from '../img/icons/arrow.svg';

function ButtonBack(props) {
    return (
        <Link to={props.to}>
            <Button variant="secondary" className="bg-input border-0 h-50px rounded-3 w-50px">
                <img src={arrow} alt="arrow-back" />
            </Button>
        </Link>

    );
}

export default ButtonBack;
