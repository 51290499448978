
import Backdrop from "../components/backdrop";
import Content from "../components/content";
import Nav from "../components/nav";
import SaleScores from "../components/salescores";

function Home() {
    return (
        < >
            <Backdrop>
                <div className="pt-5 pb-4 text-center text-white">
                    <div className="m-auto">
                        <div className="text-uppercase letter-spacing-12px font-size-20px font-weight-600">This Month</div>
                        <div className="font-weight-600 font-size-20px">November Sales</div>
                        <div className="font-weight-900 font-size-160px text-shadow-lg">24</div>
                        <div className="text-uppercase letter-spacing-3px font-size-16px font-weight-600">Personal Stats</div>
                    </div>
                </div>
            </Backdrop>
            <Content>
                <SaleScores />
            </Content>
            <div className="fixed-bottom">
                <Nav />
            </div>

        </>
    );
}

export default Home;
