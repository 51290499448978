import Form from 'react-bootstrap/Form';

function InputForm(props) {
    return (
        <Form>
            <Form.Group className="" >
                {props.children}
            </Form.Group>
        </Form>
    );
}

export default InputForm;
