import { Link } from "react-router-dom";
import Button from "./button";

function LinkButton(props) {
    return (
        <Link className="text-decoration-none" to={props.to || '#'}>
            <Button className={props.className} icon={props.icon} disabled={props.disabled} label={props.label} onClick={props.onClick} />
        </Link>

    );
}

export default LinkButton;
