function Logo(props) {
    return (
        <div className="font-size-50px text-white">
            <span>KHRES</span>
            <span className="font-weight-600">ME</span>
        </div>

    );
}

export default Logo;
