import TopDepartment from "./topdepartment";
import goldStar from "../img/trophies/star_gold.png";
import silverStar from "../img/trophies/star_silver.png";
import bronzeStar from "../img/trophies/star_bronze.png";

export function TopDepartments(props) {

    return (

        <div className="pt-3 ">
            <div className="p-2">
                <div className="mb-3 text-center text-uppercase letter-spacing-12px font-size-20px font-weight-600 text-white">DOTM</div>
                <div className="rounded bg-input">
                    <TopDepartment className="text-black" image={goldStar} name="Rentention" rank="1" score="92.8" alt="agent-2" />
                    <hr className="m-0" />
                    <TopDepartment className="text-black" image={silverStar} name="Outbound" rank="2" score="91.1" alt="agent-1" />
                    <hr className="m-0" />
                    <TopDepartment className="" image={bronzeStar} name="Inbound" rank="3" score="87.4" alt="agent-3" />
                    <hr className="m-0" />
                    <TopDepartment className="" name="Tenancy" rank="4" score="82.0" alt="agent-3" />
                    <hr className="m-0" />
                    <TopDepartment className="" name="Pay" rank="5" score="80.3" alt="agent-3" />
                </div>
            </div>
        </div>

    );
}
