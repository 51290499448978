import { useState, useEffect } from "react";
import useLocalStorage from "use-local-storage";

export async function api(method, url, body, sessionToken) {

    const res = await fetch(encodeURI(`${"https://api.appery.io/rest/1/db"}${url}`), {
        method,
        headers: url === "/login" ? {
            'Content-Type': 'application/json',
            'X-Appery-Database-Id': "63a2c71a0f0d314f9335e20b",
        } : {
            'Content-Type': 'application/json',
            'X-Appery-Database-Id': "63a2c71a0f0d314f9335e20b",
            'X-Appery-Session-Token': sessionToken,
        },
        body: body ? JSON.stringify(body) : null,
    })

    return res.json();

}

export function useFetch(method, url, body) {
    const [data, setData] = useState(null);
    const [sessionToken] = useLocalStorage("sessionToken");
    const [lastUsedAt, setLastUsedAt] = useLocalStorage("lastUsedAt", 0);

    useEffect(() => {
        async function getData() {
            const res = await api(method, url, body, sessionToken);
            setData(res);
            setLastUsedAt(Date.now());
        }

        if (method && url)
            getData();

    }, [setData, setLastUsedAt, sessionToken, method, url, body]);

    return data ? [data, true, lastUsedAt] : [];
}

export function useFetchAgentByAgentCode(agentCode) {
    const [data, isLoaded] = useFetch('GET', `/collections/agents?where={"agentCode":"${agentCode}"}`);
    return data?.length ? [data[0], isLoaded] : [];
};

export function useFetchAgents() {
    const [data, isLoaded] = useFetch('GET', `/collections/agents`);
    return [data, isLoaded];
};

export function useFetchAgentByUserId(userId) {
    const [data, isLoaded] = useFetch('GET', userId ? `/collections/agents?where={"userId":"${userId}"}` : '');
    return data?.length ? [data[0], isLoaded] : [];
};

export function useAuthenticate(auth) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [authError, setAuthError] = useState();
    const [agent, setAgent] = useLocalStorage("user", "");
    const [sessionToken, setToken] = useLocalStorage("sessionToken", "");
    const [lastUsedAt, setLastUsedAt] = useLocalStorage("lastUsedAt", 0);

    useEffect(() => {
        async function getData(auth) {
            setIsLoading(true);
            const res = await api('POST', '/login', auth);
            if (res?.sessionToken) {
                const agent = await api('GET', `/collections/agents?where={"userId":"${res['_id']}"}`, null, res.sessionToken);
                setAgent(agent[0]);
                setToken(res.sessionToken);
                setLastUsedAt(Date.now());
                setIsLoggedIn(true);
                setIsLoading(false);
            } else {
                setAuthError(res);
                setIsLoading(false);
            }
        }

        if (auth?.username && auth?.password)
            getData(auth);

    }, [auth, setToken, setAgent, setIsLoading, setLastUsedAt]);

    return [isLoggedIn, authError, isLoading, lastUsedAt, agent, sessionToken];

}
