import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/hw.css';
import './css/font.css';
import { Protected } from "./pages/protected";

function App() {
  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6} xxl={4} className="p-0 mb-85px ">
          <Protected />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
