import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTime } from "use-time-react-hook";

export const Protected = () => {

    const [time] = useTime();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const lastUsedAt = localStorage.getItem("lastUsedAt");
        const user = localStorage.getItem("user");

        if (((Date.now() - lastUsedAt > 86400000) || !user)
            && !["/", "/sign-in", "/sign-up"].includes(location.pathname)) {
            navigate('/sign-in');
        }

    }, [time, location.pathname, navigate]);

    return (
        <Outlet />
    )
};