import { Link } from 'react-router-dom';
import pencilIcon from '../img/icons/pencil.svg';
import AgentImage from './agentimage';
import { useFetchAgentByAgentCode } from '../hooks/useFetch';

function AgentProfile(props) {

    const [user, isLoaded] = useFetchAgentByAgentCode(props.agentCode);

    return (
        <div>
            {props.allowEdit && isLoaded &&
                <div className="d-flex position-absolute w-100 justify-content-end">
                    <div className="bg-half m-2 p-2 rounded-3">
                        <Link to="/me/edit">
                            <img className="w-40px" src={pencilIcon} alt="edit" />
                        </Link>
                    </div>
                </div>
            }

            <div className="d-flex align-items-end">

                <div className="position-absolute bg-half w-100 p-2 mb-3">

                    <div className="font-size-36px font-weight-600 text-white d-flex justify-content-between">
                        <div className="text-shadow">{user?.fullName}</div>
                    </div>
                    <div className="text-uppercase letter-spacing-3px text-brand">
                        {user?.jobTitle}
                    </div>
                </div>

                <AgentImage className="w-100" waitForLoad={true} isLoaded={isLoaded} image={user?.profileImage?.fileurl} />

            </div>
        </div>
    );
}

export default AgentProfile;
