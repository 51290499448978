import NavItem from "./navitem";

import chart from '../img/icons/chart.svg';
import tv from '../img/icons/tv.svg';
import user from '../img/icons/user.svg';
import star from '../img/icons/star.svg';
import exit from '../img/icons/exit.svg';

function Nav(props) {
    return (
        <div className="pt-3 pb-3 bg-input d-flex flex-col justify-content-around align-items-center">

            <NavItem to="/home">
                <img src={chart} alt="chart" />
            </NavItem>

            <NavItem to="/social">
                <img src={tv} alt="tv" />
            </NavItem>

            <NavItem to="/me">
                <img src={user} alt="user" />
            </NavItem>

            <NavItem to="/leader-board">
                <img src={star} alt="star" />
            </NavItem>

            <NavItem to="/sign-in">
                <img src={exit} alt="exit" />
            </NavItem>
        </div>
    );
}

export default Nav;
