import { useState, useEffect } from 'react';

function SocialQuote(props) {

    const [item, setQuote] = useState("");

    function rand(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    useEffect(() => {

        const quotes = [
            { quote: "Our greatest weakness lies in giving up. The most certain way to succeed is always to try just one more time", author: "Thomas Edison" },
            { quote: "Learn from the mistakes of others. You can't live long enough to make them yourself", author: "Eleanor Roosevelt" },
            { quote: "Always do your best. What you plant now, you will harvest later", author: "Og Mandino" },
            { quote: "You're not obligated to win. You're obligated to keep trying to do the best you can every day", author: "Marian Wright Edelman" },
            { quote: "If you are not taking care of your customer, your competitor will.", author: "" },
            { quote: "It isn't about cost - it's about experience", author: "Christian Nellemann" },
        ];

        setQuote(quotes[rand(0, quotes.length - 1)]);

    }, []);


    return (
        <>
            <q>{item.quote}</q>
            <span> - {item.author}</span>
        </>
    );
}

export default SocialQuote;
